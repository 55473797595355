import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout/Layout"
import WideContainer from "../components/layout/WideContainer"
import ParkSectionText from "../components/nationalpark/ParkSectionText"
import MetaData from "../components/seo/MetaData"
import OrganizationStructuredData from "../components/seo/OrganizationStructuredData"
import Colors from "../constants/Colors"

interface Props {
  location: any
}

export default function CountriesPage({ location }: Props) {
  return (
    <Layout>
      <MetaData
        title="National parks in countries"
        description="See a list of all the national parks in the different countries. Complete with a map and ideas for sights, hiking and more."
        pageUrl={location.pathname}
      />
      <OrganizationStructuredData />
      <WideContainer>
        <div className={`container mx-auto md:px-2 px-2`}>
          <h1 className="mt-6 md:mt-8 mb-0">
            Explore all national parks in a country
          </h1>
          <p className="mt-2">
            All countries have beautiful nature to showcase. Select a country
            here and view all the national parks.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 text-center py-4">
          <Link to={`/country/denmark/`} title={`Country: Denmark`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/denmark.jpg"
                  alt="National Parks in Denmark"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Denmark
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/germany/`} title={`Country: Germany`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/germany.jpeg"
                  alt="National Parks in Germany"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Germany
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/france/`} title={`Country: France`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/france.jpg"
                  alt="National Parks in France"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    France
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/spain/`} title={`Country: Spain`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/spain.jpeg"
                  alt="National Parks in Spain"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Spain
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/italy/`} title={`Country: Italy`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/italy.jpg"
                  alt="National Parks in Italy"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Italy
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/croatia/`} title={`Country: Croatia`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/croatia.jpg"
                  alt="National Parks in Croatia"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Croatia
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/netherlands/`} title={`Country: Netherlands`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/netherlands.jpg"
                  alt="National Parks in Netherlands"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Netherlands
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/bulgaria/`} title={`Country: Bulgaria`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/bulgaria.jpg"
                  alt="National Parks in Bulgaria"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Bulgaria
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/poland/`} title={`Country: Poland`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/poland.jpg"
                  alt="National Parks in Poland"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Poland
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/montenegro/`} title={`Country: Montenegro`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/montenegro.jpg"
                  alt="National Parks in Montenegro"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Montenegro
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/sweden/`} title={`Country: Sweden`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/sweden.jpg"
                  alt="National Parks in Sweden"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Sweden
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link
            to={`/country/united-kingdom/`}
            title={`Country: United Kingdom`}
          >
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/united-kingdom.jpg"
                  alt="National Parks in United Kingdom"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    United Kingdom
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link
            to={`/country/czech-republic/`}
            title={`Country: Czech Republic`}
          >
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/czech.jpg"
                  alt="National Parks in Czech Republic"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Czech Republic
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/switzerland/`} title={`Country: Switzerland`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/switzerland.jpg"
                  alt="National Parks in Switzerland"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Switzerland
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/slovakia/`} title={`Country: Slovakia`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/slovakia.jpg"
                  alt="National Parks in Slovakia"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Slovakia
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/norway/`} title={`Country: Norway`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/norway.jpg"
                  alt="National Parks in Norway"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Norway
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/iceland/`} title={`Country: Iceland`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/iceland.jpg"
                  alt="National Parks in Iceland"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Iceland
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/albania/`} title={`Country: Albania`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/albania.jpg"
                  alt="National Parks in Albania"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Albania
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link
            to={`/country/north-macedonia/`}
            title={`Country: North Macedonia`}
          >
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/north-macedonia.jpg"
                  alt="National Parks in North Macedonia"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    North Macedonia
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link
            to={`/country/bosnia-and-herzegovina/`}
            title={`Country: Bosnia & Herzegovina`}
          >
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/bosnia-and-herzegovina.jpg"
                  alt="National Parks in Bosnia & Herzegovina"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Bosnia & Herzegovina
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/slovenia/`} title={`Country: Slovenia`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/slovenia.jpg"
                  alt="National Parks in Slovenia"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Slovenia
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/romania/`} title={`Country: Romania`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/romania.jpg"
                  alt="National Parks in Romania"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Romania
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/portugal/`} title={`Country: Portugal`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/portugal.jpg"
                  alt="National Parks in Portugal"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Portugal
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/greece/`} title={`Country: Greece`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/greece.jpg"
                  alt="National Parks in Greece"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Greece
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/moldova/`} title={`Country: Moldova`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/moldova.jpg"
                  alt="National Parks in Moldova"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Moldova
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/lithuania/`} title={`Country: Lithuania`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/lithuania.jpg"
                  alt="National Parks in Lithuania"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Lithuania
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/estonia/`} title={`Country: Estonia`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/estonia.jpg"
                  alt="National Parks in Estonia"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Estonia
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/latvia/`} title={`Country: Latvia`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/latvia.jpg"
                  alt="National Parks in Latvia"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Latvia
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/serbia/`} title={`Country: Serbia`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/serbia.jpg"
                  alt="National Parks in Serbia"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Serbia
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/finland/`} title={`Country: Finland`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/finland.jpg"
                  alt="National Parks in Finland"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Finland
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/ireland/`} title={`Country: Ireland`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/ireland.jpg"
                  alt="National Parks in Ireland"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Ireland
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/austria/`} title={`Country: Austria`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/austria.jpg"
                  alt="National Parks in Austria"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Austria
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/hungary/`} title={`Country: Hungary`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/hungary.jpg"
                  alt="National Parks in Hungary"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Hungary
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/belgium/`} title={`Country: Belgium`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/belgium.jpg"
                  alt="National Parks in Belgium"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Belgium
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/ukraine/`} title={`Country: Ukraine`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/ukraine.jpg"
                  alt="National Parks in Ukraine"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Ukraine
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/country/malta/`} title={`Country: Malta`}>
            <div className="md:px-2 px-2 mb-4">
              <div
                className="img-hover-zoom rounded"
                style={{ display: "grid" }}
              >
                <StaticImage
                  src="../images/countries/malta.jpg"
                  alt="National Parks in Malta"
                  placeholder="blurred"
                  className="w-full object-cover"
                  style={{
                    gridArea: "1/1",
                  }}
                />
                <div
                  style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "end center",
                    display: "grid",
                  }}
                  className="overlay-effect"
                >
                  <h3
                    style={{
                      color: Colors.white,
                      marginBottom: 20,
                    }}
                  >
                    Malta
                  </h3>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </WideContainer>
      <ParkSectionText />
    </Layout>
  )
}
